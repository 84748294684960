import React from "react";
import { Col, Image } from "react-bootstrap";

const ImageWithCaption = (props) => {
  return (
    <Col className="image-with-caption mt-5">
      <Image src={props.src} rounded />
      <h5 className="mt-2">{props.name}</h5>
      <h5>{props.position}</h5>
      <h5>{props.program}</h5>
    </Col>
  );
};
export default ImageWithCaption;
