import React from "react";
import { Footer, LCGNav } from "../components";
import { Image, Card, Container, Row, Col } from "react-bootstrap";

import HEADER from "../assets/images/lcg-page/LCG_HEADER.webp";
import ABOUT from "../assets/images/lcg-page/LCG_ABOUT.jpg";
import SERVICES from "../assets/images/lcg-page/LCG_SERVICES.jpg";

const LCGPage = () => {
  return (
    <div className="lcg-page">
      <div className="wrapper">
        <LCGNav />
        <Card className="bg-dark text-white">
          <Card.Img src={HEADER} alt="Card image" style={{ height: "40vh" }} />
          <Card.ImgOverlay className="mt-5 pt-5">
            <h1 className="text-center lcg-header">Laurier Consulting Group</h1>
          </Card.ImgOverlay>
        </Card>
        <Container className="about-section">
          <Row className="mt-5">
            <h1 className="text-center mb-4">What we do</h1>
            <Col className="mt-5">
              <p>
                The Laurier Consulting Group is a program that provides pro-bono
                consulting services to start-ups, small to medium businesses,
                and non-profits while providing students real world consulting
                experience.{" "}
              </p>
              <br />
              <p>
                Teams of 4-5 of Laurier’s best and brightest students are
                assigned to clients to try and solve their most pressing
                strategic problems or capitalize on opportunities.
              </p>
            </Col>
            <Col>
              <Image id="lcg-about" src={ABOUT} />
            </Col>
          </Row>
          <h1 className="text-center mb-3 mt-5">Our Services:</h1>
          <Row className="justify-content-around mt-3">
            <Image src={SERVICES} id="services" />
          </Row>
          <h1 className="text-center mt-5">Get Involved</h1>
          <h4 className="text-center mt-5">
            If you’re interested in working with LCG, email us at{" "}
            <a
              href="mailto:shaleequreshi2019@gmail.com"
              style={{ textDecoration: "none" }}>
              lcg2@laurierconsultingclub.ca
            </a>
          </h4>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
export default LCGPage;
