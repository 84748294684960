import { Container, Nav, Navbar, NavDropdown, Image } from "react-bootstrap";

import LCG_LOGO from "../assets/images/LCC logo.png";

function LCGNav() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary lcg-nav">
      <Container>
        <Navbar.Brand href="/" id="brand">
          <Image src={LCG_LOGO} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav id="center-contents">
            <Nav.Link href="/" id="blue">
              HOME
            </Nav.Link>
            <Nav.Link href="/team">TEAM</Nav.Link>
            <Nav.Link href="/lcg">LCG</Nav.Link>
            <Nav.Link href="/resources">RESOURCES</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="mailto:lcc@lazsoc.ca" className="contact-nav">
              CONTACT US
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default LCGNav;
