import React from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import {
  HomePage,
  TeamsPage,
  LCGPage,
  ResourcesPage,
  ContactPage,
} from "../pages";

const verify_element = () => {};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/contact" exact element={<ContactPage />} /> */}
        <Route path="/resources" exact element={<ResourcesPage />} />
        <Route path="/lcg" exact element={<LCGPage />} />
        <Route path="/team" exact element={<TeamsPage />} />
        <Route path="/" exact element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
