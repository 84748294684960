import React from "react";
import { Footer, ImageWithCaption, LCGNav } from "../components";
import { Container, Row } from "react-bootstrap";

import IMG from "../assets/me2.jpeg";

// Teams page
import ALI from "../assets/images/team/Screen Shot 2023-07-27 at 1.33.45 PM.png";
import SAAD from "../assets/images/team/saad.jpeg";
import HARPER from "../assets/images/team/Screen Shot 2023-10-01 at 12.25.51 PM.png";
import MOUSSA from "../assets/images/team/moussa.jpg";
import OLIVIA from "../assets/images/team/olivia.jpg";
import SHREYA from "../assets/images/team/shreya.jpg";
import ALAIN from "../assets/images/team/alain.jpg";
import ADAM from "../assets/images/team/Adam.png";
import TABITHA from "../assets/images/team/tabitha.jpg";
import ROSE from "../assets/images/team/Screen Shot 2023-10-01 at 12.29.12 PM.png";
import DAVID from "../assets/images/team/david.jpg";
import ADITYA from "../assets/images/team/aditya.jpg";

const TeamsPage = () => {
  return (
    <div className="teams-page">
      <div className="wrapper">
        <LCGNav />
        <Container>
          <h1 className="text-center mt-5">2023/24 Executive Team</h1>
          <Row className="justify-content-around text-center">
            <ImageWithCaption
              src={HARPER}
              name="Harper Kroft"
              position="President"
              program="3rd year BBA"
            />
            <ImageWithCaption
              src={MOUSSA}
              name="Moussa Elsayed"
              position="Executive Vice President"
              program="3rd year BBA"
            />
            <ImageWithCaption
              src={SAAD}
              name="Saad Khan"
              position="Co-President of LCG"
              program="3rd year BBA"
            />
            <ImageWithCaption
              src={ALI}
              name="Ali Movasaghi"
              position="Co-President of LCG"
              program="3rd year BBA"
            />
            <ImageWithCaption
              src={OLIVIA}
              name="Olivia Xoxa"
              position="VP of Internal"
              program="4th year BBA"
            />
            <ImageWithCaption
              src={SHREYA}
              name="Shreya Dalal"
              position="VP of Events"
              program="3rd year BBA"
            />
            <ImageWithCaption
              src={ALAIN}
              name="Alain Lai"
              position="VP of Events"
              program="2nd year BBA"
            />
            <ImageWithCaption
              src={ADAM}
              name="Adam Izumi"
              position="VP of Corporate"
              program="2nd year BBA"
            />
            <ImageWithCaption
              src={TABITHA}
              name="Tabitha Wong"
              position="VP of Corporate"
              program="4th year CSBBA"
            />
            <ImageWithCaption
              src={ROSE}
              name="Rose Parackal"
              position="VP of Marketing"
              program="3rd year Math/BBA"
            />
            <ImageWithCaption
              src={DAVID}
              name="David Besprozvanny"
              position="VP of Finance"
              program="4th year BBA"
            />
            <ImageWithCaption
              src={ADITYA}
              name="Aditya Narayanan"
              position="VP of Education"
              program="2nd year BBA"
            />
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default TeamsPage;
