import React from "react";
import { Footer, ImageWithText, LCGNav } from "../components";
import { Link } from "react-scroll";
import { Button, Col, Container, Image, Row, Card } from "react-bootstrap";

// Images
import HOME_WELCOME from "../assets/images/home/HOME_WELCOME.jpg";
import HOME_CASE_COMPS from "../assets/images/home/HOME_CASECOMPS.jpg";
import HOME_CONSULTING from "../assets/images/home/HOME_CONSULTING.jpg";
import HOME_PANELS from "../assets/images/home/HOME_PANELS.jpg";

// Partners
import DELOITTE from "../assets/images/home/PAST PARTNERS/DELOITTE.png";
import MICROSOFT from "../assets/images/home/PAST PARTNERS/MICROSOFT.webp";
import BCG from "../assets/images/home/PAST PARTNERS/BCG.png";
import PWC from "../assets/images/home/PAST PARTNERS/PWC.png";
import GARTNER from "../assets/images/home/PAST PARTNERS/GARTNER.png";
import ALPHA from "../assets/images/home/PAST PARTNERS/ALPHAFMC.png";
import KONRAD from "../assets/images/home/PAST PARTNERS/KONRAD.png";
import COKE from "../assets/images/home/PAST PARTNERS/COCACOLA.png";
import MACKENZIE from "../assets/images/home/PAST PARTNERS/MACKENZIE INVESTMENTS.png";
// import SATOV from "../assets/images/home/PAST PARTNERS/SATOV.png";
import SATOV from "../assets/images/home/PAST PARTNERS/satov2.png";
import IBM from "../assets/images/home/PAST PARTNERS/IBM.png";

const HomePage = () => {
  return (
    <div>
      <div className="wrapper landing">
        <LCGNav />
        <div className="left" id="landing-text">
          <h1>Laurier Consulting Club</h1>
          <p>Wilfrid Laurier's Premier Consulting Club</p>
          <Link
            activeClass="active"
            to="learn-more"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}>
            <Button href="" variant="outline-light">
              LEARN MORE
            </Button>
          </Link>
        </div>
      </div>
      <section id="learn-more">
        <div className="pt-5">
          <Container>
            <Row className="learn-more-landing">
              <Col className="mt-5">
                <h1>Welcome to the Laurier Consulting Club!</h1>
                <p>
                  Our goal is to introduce Laurier’s students to the world of
                  consulting while helping them prepare for careers in
                  consulting. We expose, train, and prepare the next generation
                  of Laurier students for high-impact consulting careers.
                </p>
              </Col>
              <Col>
                <Image id="home-welcome" src={HOME_WELCOME} />
              </Col>
            </Row>
            <hr className="border-sep text-center mt-5 mb-2" />
            <Row className="mt-5 img-card">
              <h1 className="text-center mb-5" id="title">
                How We Do It:
              </h1>
              <Col>
                <Card className="bg-dark text-white">
                  <Card.Img
                    src={HOME_CASE_COMPS}
                    alt="Card image"
                    id="card-img-home"
                  />
                  <Card.ImgOverlay>
                    <Card.Title id="card-title">Case Competitions</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col>
                <Card className="bg-dark text-white">
                  <Card.Img
                    src={HOME_PANELS}
                    alt="Card image"
                    id="card-img-home"
                  />
                  <Card.ImgOverlay>
                    <Card.Title id="card-title">
                      Networking Events and Speaker Panels
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col>
                <Card className="bg-dark text-white">
                  <Card.Img
                    src={HOME_CONSULTING}
                    alt="Card image"
                    id="card-img-home"
                  />
                  <Card.ImgOverlay>
                    <Card.Title id="card-title">Pro-bono Consulting</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            </Row>
            <hr className="border-sep text-center mt-5 mb-2" />
            <h1 id="title" className="text-center mb-5 mt-5">
              Our Past Partners
            </h1>
            <Row className="past-partners mb-5">
              <Container className="text-center">
                <Image src={DELOITTE} className="mb-3" />
                <Image src={MICROSOFT} className="mb-3" />
                <Image src={BCG} className="mb-3" />
                <Image src={PWC} className="mb-3" />
                <br />
                <Image src={GARTNER} className="mb-3" />
                <Image src={ALPHA} className="mb-3" />
                <Image src={KONRAD} className="mb-3" />
                <br />
                <Image src={COKE} className="mb-3" />
                <Image src={MACKENZIE} className="mb-3" />
                <Image src={SATOV} className="mb-3" />
                <Image src={IBM} className="mb-3" />
              </Container>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default HomePage;
