import React, { useEffect } from "react";
import { Footer, LCGNav } from "../components";
import { Button, Container, Row, Image } from "react-bootstrap";

// Resources
import GUIDE_TO_MBB from "../assets/resources/Sample - Guilde to an MBB job offer.pdf";
import MBB_STUDY_PLAN from "../assets/resources/MBB_Study_Plan_CaseBasix.pdf";
import MCKINSEY_SOLVE_GAME_ULTIMATE_GUIDE from "../assets/resources/1. McKinsey Solve Game - Ultimate Guide.pdf";
import MCKINSEY_SOLVE_GAME_ECOSYSTEM from "../assets/resources/2. McKinsey Solve Game - Ecosystem Building Guide.pdf";
import MCKINSEY_SOLVE_GAME_RED from "../assets/resources/3. McKinsey Solve Game - Red Rock Study Guide.pdf";
import MCKINSEY_SOLVE_GAME_PLANT from "../assets/resources/4. McKinsey Solve Game - Plant Defense Guide.pdf";
import BCG_CASEY_CHATBOT from "../assets/resources/5. BCG Casey Chatbot - Ultimate Guide.pdf";
import BCG_PYMETRICS from "../assets/resources/6. BCG Pymetrics Test - Ultimate Guide.pdf";

// Sponsor images
import CASEBASIX from "../assets/images/resources/casebasix.jpeg";
import ROCKETBLOCKS from "../assets/images/resources/rocketblocks.png";

const ResourcesPage = () => {
  const openPDF = (e, file) => {
    e.preventDefault();
    window.open(file, "_blank");
  };

  return (
    <div className="resources">
      <div className="wrapper">
        <LCGNav />
        <Container>
          <div className="text-center">
            <Image src={CASEBASIX} className="sponsor-img" />
          </div>
          <h1
            className="mt-1 text-center"
            style={{ fontFamily: "Poppins-SemiBold" }}>
            CaseBasix Resources
          </h1>
          <h5 className="text-center mb-3">Click each button to open a PDF</h5>
          <div className="text-center mb-3">
            <h5 className="mb-2">
              COUPON CODE: <span className="discount-code">lcc20off</span> for
              20% off!
            </h5>
            <a
              href="https://www.casebasix.com/"
              target="_blank"
              rel="noopener noreferrer">
              Link to CaseBasix
            </a>
          </div>
          <Row className="justify-content-around">
            <Button
              className="w-100 mt-2 mb-3"
              variant="outline-dark"
              href="https://www.casebasix.com/pages/free-consulting-interview-prep-resources"
              target="_blank">
              Free Case Interview Prep Resources
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, GUIDE_TO_MBB);
              }}>
              Guide to MBB Job Offer
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, MBB_STUDY_PLAN);
              }}>
              MBB Study Plan
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, MCKINSEY_SOLVE_GAME_ULTIMATE_GUIDE);
              }}>
              McKinsey Solve Game - Ultimate Guide
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, MCKINSEY_SOLVE_GAME_ECOSYSTEM);
              }}>
              McKinsey Solve Game - Ecosystem Building Guide
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, MCKINSEY_SOLVE_GAME_RED);
              }}>
              McKinsey Solve Game - Red Rock Study Guide
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, MCKINSEY_SOLVE_GAME_PLANT);
              }}>
              McKinsey Solve Game - Plant Defense Guide
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, BCG_CASEY_CHATBOT);
              }}>
              BCG Casey Chatbot - Ultimate Guide
            </Button>
            <Button
              className="w-50 mt-2 mb-3"
              variant="outline-dark"
              onClick={(e) => {
                openPDF(e, BCG_PYMETRICS);
              }}>
              BCG Pymetrics Test - Ultimate Guide
            </Button>
          </Row>
          <hr className="border-sep text-center mt-2 mb-2" />
          <div className="text-center">
            <Image src={ROCKETBLOCKS} className="sponsor-img mt-5" />
            <h1 className="text-center mt-5">RocketBlocks Discount</h1>
            <h5 className="text-center mt-2">
              COUPON CODE: <span className="discount-code">lcc2023</span> for
              20% off!
            </h5>
            <a
              href="https://www.rocketblocks.me/"
              target="_blank"
              rel="noopener noreferrer">
              Link to RocketBlocks
            </a>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};
export default ResourcesPage;
