import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import FOOTER_IMG from "../assets/images/FOOTER.jpg";

const Footer = () => {
  return (
    <div>
      <Image src={FOOTER_IMG} id="footer-img" />
      <div className="lcc-footer pt-5 pb-5">
        <Container className="footer">
          <Row>
            <Col id="col-1">
              <h1>Laurier Consulting Club</h1>
              <h5 className="mt-3">64 University Ave</h5>
              <h5>W, Waterloo, ON</h5>
              <h5>N2L 3C7</h5>
              <a
                className="mt-2"
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:lcc@lazsoc.ca
">
                lcc@lazsoc.ca
              </a>
            </Col>
            <Col id="col-2">
              <h2>More</h2>
              <a href="/team">Team</a>
              <br />
              <a href="/lcg">LCG</a>
              <br />
              <a href="/resources">Resources</a>
            </Col>
            <Col id="col-3">
              <h2>Socials</h2>
              <a
                href="https://www.instagram.com/laurierconsultingclub/"
                target="_blank"
                rel="noopener noreferrer">
                Instagram
              </a>
              <br />
              <a
                href="https://www.linkedin.com/company/laurier-consulting-club/"
                target="_blank"
                rel="noopener noreferrer">
                LinkedIn
              </a>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="pb-3 pt-3 text-center srk-copyright">
        <a
          href="https://www.linkedin.com/in/qureshishahrukh/"
          target="_blank"
          rel="noopener noreferrer">
          © Shahrukh Qureshi
        </a>
      </div>
    </div>
  );
};
export default Footer;
